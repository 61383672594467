import './App.css';
import { FiPhone } from "react-icons/fi";
import { IoCaretForwardCircle } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";

function App() {
  return (
    <div className="flex flex-col w-full justify-center items-center app">
      <div className='flex justify-end bg-[#04599a] w-full p-2 shadow-inner'>
        <div className='flex flex-col text-white p-2 gap-1 w-[45%]'>
            <div className='flex flex-row items-center font-bold text-md gap-2'>
              <FiPhone /> 
              Call us on : 
            </div>
            <div className='flex flex-row items-center gap-2'>
              <div className='flex flex-row items-center gap-2'>
                <img src="/img/flag/united-kingdom.png" alt="UK Flag" width="20" height="20" />
                <span><a className="text-[12px]" href="tel:+442037553691">+ 44 203 755 3691</a></span>
              </div>
              <div className='flex flex-row items-center gap-2'>
                <img src="/img/flag/singapore.png" alt="UK Flag" width="20" height="20" />
                <span><a className="text-[12px]" href="tel:+6566225474">+ 65 6622 5474</a></span>
              </div>
              <div className='flex flex-row items-center gap-2'>
                <img src="/img/flag/hong-kong.png" alt="UK Flag" width="20" height="20" />
                <span><a className="text-[12px]" href="tel:+85225846263">+ 852 2584 6263</a></span>
              </div>
              <div className='flex flex-row items-center gap-2'>
                <img src="/img/flag/thailand.png" alt="UK Flag" width="20" height="20" />
                <span><a className="text-[12px]" href="tel:+6625427233">+ 66 254 7233</a></span>
              </div>     
            </div>         
        </div>
      </div>

      <div className='flex flex-col bg-white shadow-xl' style={{
        width: '60%',
      }}>
        <div className='flex flex-row justify-between'>
          <div className='absolute ml-5' style={{
            top: 20,
            zIndex: 50,
            }}>
            <img src="/img/logo.jpg" alt="Logo" width="250" height="250" className='flex shadow-lg object-cover'/>
          </div>
          <div className='flex mt-2 mb-2 p-2 ml-[30%]'>
            <ul className='flex flex-row gap-4 md:gap-3 sm:gap-2 text-gray-500 text-sm font-semibold'>
              <li>Home</li>
              <li>|</li>
              <li>Corporate Profile</li>
              <li>|</li>
              <li>Our Aim &amp; Services</li>
              <li>|</li>
              <li>Business Activity &amp; Objective</li>
              <li>|</li>
              <li>Contact Us</li>
            </ul>
          </div>
        </div>

        <div className='flex flex-col'>
          <div className='relative'>
            <div className='flex' style={{
              height: '350px',
              width: '100%',
              backgroundImage: `url(/img/bg_banner.jpg)`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}>
              <div className='flex flex-col mt-20 ml-5 w-[40%]'>
              <h2 className='text-3xl font-semibold text-gray-600'>Professional Business Consultant</h2>
              <p className='text-blue-500 text-md w-2/2 mt-5'>
              Obviously everyone wants to be successful, but we want to be looked back on as being very innovative,
              very trusted and ethical and ultimately making a big difference in the business world.
              </p>
             
              <div className=' mt-5 items-center gap-1'>
                <a href="#" className='flex flex-row gap-1 items-center'>
                  <IoCaretForwardCircle className='text-gray-400 text-xl shadow-2xl'/>
                  <p className='text-gray-600 text-lg'>learn more about us</p>
                </a>
              </div>
              </div>
            </div>
          </div>

          <div className='flex flex-col p-2'>
            <div className='flex flex-row'>
              <div className='flex flex-col' style={{width: '32%'}}>
                <div className='flex flex-col mt-2' style={{
                  minHeight: '200px',
                  backgroundImage: `url(/img/bg_business.jpg)`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat'
                }}>
                  <div className='flex flex-col p-5'>
                    <h2 className='text-lg font-semibold text-blue-700'>A PATH TO BUSINESS</h2>
                    <span className='text-[#478ab7] font-semibold text-xl'>SUCCESS!</span>

                    <hr style={{
                      width: '100%',
                      border: '0.5px dashed #478ab7',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}/>

                    <span className='text-blue-700 font-bold text-sm'>Why Choose Us</span>
                    <ul className='text-gray-600 text-[12px] mt-1' style={{

                    }}>
                      <li className='flex flex-row items-center'><IoIosArrowForward /> Business Advisory <IoIosArrowForward className='ml-2'/> Business Valuation</li>
                      <li className='flex flex-row items-center'><IoIosArrowForward /> Corporate Finance <IoIosArrowForward className='ml-2'/> Financial Due Diligence</li>
                      <li className='flex flex-row items-center'><IoIosArrowForward /> Liquidation and Company Restructuring</li>
                      <li className='flex flex-row items-center'><IoIosArrowForward /> Finance and Banking</li>
                    </ul>

                  </div>
                </div>
                <img src="/img/link1.jpg" alt="img_business" width="100%" height="auto" className='flex shadow-lg mt-2' style={{
                  objectFit: 'contain',
                }} />
              </div>


              <div className='flex flex-col mt-2 p-5 w-[68%]'>
                <h2 className='text-xl font-semibold text-gray-600'>
                  Welcome to 
                  <span className='text-blue-700 font-semibold text-xl ml-1'>Broadhurst Pacific</span>
                  </h2>
                  <div className='flex flex-row mt-2 gap-2'>
                    <img src="/img/img_welcome.jpg" alt="img_welcome" width="150" height="150" className='flex shadow-lg' style={{
                      objectFit: 'contain',
                    }}/>
                    <span className='text-blue-700 text-sm'>From strategic business planning to working capital management. The services provided are designed into modules and packages that best meet customers' specific requirements.
                    <span className='text-gray-700'>This ensures the customer obtains value for money and also provides flexibility for their own business planning and management.</span>
                    </span>
                  </div>
                  <p className='text-blue-700 text-sm mt-5'>We are able to provide you with all the technical support required to ensure that your business and investments operate effectively and economically.
                    <span className='text-gray-700'>Our clients also have access to our associate firms for technical and management assistance where new areas of expertise are necessary to meet your specific requirements.</span>
                  </p>
                  <div className='flex flex-row mt-2 gap-2 w-full'>
                    <img src='/img/off_shore.jpg' alt="off_shore" width="100%" height="auto" className='flex shadow-lg mt-2 md:w-[300px] lg:w-[500px]' style={{
                      objectFit: 'contain',
                    }}/>
                    <img src='/img/link3.jpg' alt="link3" width="100%" height="auto" className='flex shadow-lg mt-2 md:w-[300px] lg:w-[500px]' style={{
                      objectFit: 'contain',
                    }}/>
                  </div>
              </div>

            </div>
          </div>

          <div>

          </div>
        </div>
      </div>
        <div className='flex flex-col p-2 w-full justify-center items-center mt-2 text-sm text-gray-600'>
            <span>(c) 2016 <a href='/'><strong>Broadhurst (Pacific) Co.,Ltd.</strong></a>All Rights Reserved.</span>
        </div>
    </div>
  );
}

export default App;
